.container-fluid {
  padding: 0 !important;
}
/* .bg-one{
 background: url("../assets/images/bglogo.jpg") no-repeat center center ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size:cover;
  height: 100vh;
} */
.intro-data {
  color: #18212a;
  border: 2px double #18212a;
}
.quote {
  color: black;
  /* position: absolute;
        margin-top: 12rem;
        width: 26rem;
        margin-left: 3rem; */
  text-align: justify;
}
.mt-5 {
  margin-top: 10rem !important;
}

.heading {
  font-size: 3.5rem;
}
.img-fluid {
  vertical-align: top !important;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.pr-md-5 {
  padding-right: 3rem !important;
}
.contact-btn {
  text-decoration: none;
  padding: 0.8rem;
  background: rgb(64, 168, 90);
  border: 2px solid rgb(64, 168, 90);
  color: white;
  border-radius: 5px;
}
.contact-btn:hover {
  transition: 0.3s;
  color: white;
  background: rgb(210, 174, 109);
  border: 2px solid rgb(210, 174, 109);
}
.mt-5 {
  margin-top: 7rem !important;
}
@media (max-width: 576px) {
  /* .container {
    max-width: 540px;
  } */
  .wrapping{
    flex-wrap: wrap;
  }
  .heading{
    font-size: xx-large;
  }
  .pb-4{
    padding-bottom: 0rem !important;
  }
}
@media (max-width: 768px) {
    .wrapping{
        flex-wrap: wrap;
      }
    .heading{
        font-size: xx-large;
      }
      .pb-4{
        padding-bottom: 0rem !important;
      }
}
@media (max-width: 992px) {
  /* .container {
    max-width: 960px;
  } */
  /* .wrapping{
    flex-wrap: wrap;
  } */
}
@media (max-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
