.bg-dark{
    background-color: #4A5664 !important;
    background-image: linear-gradient(to right, #18212A , #4C5564)
}
.dropdown-menu{
    width: 3rem;
    content: inherit;
}

/* .navbar-brand{
    position: relative;
}
.navbar-brand::after{
    content: '';
    position: absolute;
    width: 100%;
    border-top: #18212A solid 2px;
} */
.navbar-brand{
    position: relative;
    text-decoration: none;
    font-family: sans-serif;
    letter-spacing: 0.5px;
}
.navbar-brand::after{
    content: '';
    position: absolute;
    background-color:  rgb(210,174,109);
    height: 3px;
    width: 0;
    left: 0;
    bottom: -10px;
    transition: 0.3s;
}
.navbar-brand:hover{
    color: #ffffff;
}
.navbar-brand:hover:after{
    width: 90%;
}
@media (max-width: 768px) {
    .smallScreenRes{
        margin-top: 50px;
        margin-left: 20px;
    }
    .navbar-brand::after{
        bottom: -1px;
    }
}

/*
.nav{
    display: flex;
    justify-content: end;

}
.flex{
    display: flex;
    gap: var(--gap,2rem);
}
.logo{
    margin: 0.5rem;
    width: 4rem;
    aspect-ratio: 1;
}
.primary-header{
    align-items: center;
    justify-content: space-between;


}
.primary-navigation{
    padding: 0;
    margin: 0;
    list-style: none;
}
.primary-navigation a{
    text-decoration: none;
    color: white;
    font-weight: 600;
}
@media (max-width : 43rem){
    .primary-navigation{
        position: fixed;
        inset: 0 0 0 30%;
        background-color: steelblue;
    }
} */
