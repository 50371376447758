@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700);
*,
*::before,
*::after {
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  display: none;
}
body{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    font-family: poppins, sans-serif;
    display:flex;
    flex-direction:column;
}


.bg-dark{
    background-color: #4A5664 !important;
    background-image: linear-gradient(to right, #18212A , #4C5564)
}
.dropdown-menu{
    width: 3rem;
    content: inherit;
}

/* .navbar-brand{
    position: relative;
}
.navbar-brand::after{
    content: '';
    position: absolute;
    width: 100%;
    border-top: #18212A solid 2px;
} */
.navbar-brand{
    position: relative;
    text-decoration: none;
    font-family: sans-serif;
    letter-spacing: 0.5px;
}
.navbar-brand::after{
    content: '';
    position: absolute;
    background-color:  rgb(210,174,109);
    height: 3px;
    width: 0;
    left: 0;
    bottom: -10px;
    transition: 0.3s;
}
.navbar-brand:hover{
    color: #ffffff;
}
.navbar-brand:hover:after{
    width: 90%;
}
@media (max-width: 768px) {
    .smallScreenRes{
        margin-top: 50px;
        margin-left: 20px;
    }
    .navbar-brand::after{
        bottom: -1px;
    }
}

/*
.nav{
    display: flex;
    justify-content: end;

}
.flex{
    display: flex;
    gap: var(--gap,2rem);
}
.logo{
    margin: 0.5rem;
    width: 4rem;
    aspect-ratio: 1;
}
.primary-header{
    align-items: center;
    justify-content: space-between;


}
.primary-navigation{
    padding: 0;
    margin: 0;
    list-style: none;
}
.primary-navigation a{
    text-decoration: none;
    color: white;
    font-weight: 600;
}
@media (max-width : 43rem){
    .primary-navigation{
        position: fixed;
        inset: 0 0 0 30%;
        background-color: steelblue;
    }
} */

/* .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100px;
    aspect-ratio: 1;
    background-color: white;
    margin: 10px;
  }
  .container {
    padding: 2px 16px;
  } */

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .card{
    cursor: pointer;
  }

.project{
  margin-top: 3rem !important
}

.service-section {
    margin-top: 70px;
    position: relative;
    height: 450px;
}
.service-section .service-bg {
    position: absolute;
    right: 0;
    width: 75%;
    top: 0;
    height: 100%;
    z-index: 1;
}
.service-section.service-odd .service-bg {
    background-color: #efcb88;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.service-section.service-even .service-bg {
    left: 0;
    background-color: #d2af6d27;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.service-item {
    display: flex;
    align-items: center;
    padding: 46px 0;
    z-index: 2;
    position: relative;
    margin-left: 7%;
}
.service-section.service-even .service-item {
    margin-right: 7%;
    flex-direction: row-reverse;
}
.service-section picture{
    min-width: 50%;
    max-width: 50%;
    max-height: 360px;
    border-radius: 10px;
    overflow: hidden;
}
.service-section picture img {
    width: 100%;
    height: 100%;    
    object-fit: cover;
    object-position: center; 
}
.service-content {
    margin-left: 2%
}
.displayProgress{
    display: none;
}
/* Media query !!! -> Always write the high in top as it will override  */
  @media (max-width: 1200px) {
    .display{
        display: block;
    }
    .displayProgress{
        display: none;
    }
  }
@media (max-width: 992px) {
    .display{
        display: block;
    }
    .displayProgress{
        display: none;
    }
  }
@media (max-width: 768px) {
    .display{
        display: none;
    }
    .displayProgress{
        display: block;
    }
  }
@media (max-width: 576px) {
    .display{
        display: none;
    }
    .displayProgress{
        display: block;
    }
  }


@charset "utf-8";
     /* Contact Page */

.rs-contact .contact-bg {
  overflow: hidden;
  position: relative;
}
.rs-contact .contact-bg .contact-address {
  margin-bottom: 45px;
}
.rs-contact .contact-bg .contact-address .address-item {
  display: flex;
  align-items: center;
}
.rs-contact .contact-bg .contact-address .address-item + .address-item {
  margin-top: 25px;
}
.rs-contact .contact-bg .contact-address .address-item .address-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #333945;
  text-align: center;
  font-size: 24px;
  margin-right: 20px;
  transition: .20s;
  color: #e88e2e;
}
.rs-contact .contact-bg .contact-address .address-item:hover .address-icon {
  background: #333945;
  color: #fff;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom {
  margin-top: 55px;
  margin-bottom: 55px;
  text-align: center;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li {
  display: inline;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li a {
  text-align: center;
  text-decoration: none;
  color: #fff;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li a i {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  line-height: 40px;
  transition: .20s;
  box-shadow: inset 2px 2px 2px;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li a i:hover {
  background: #fff;
  color: #000;
}
.rs-contact .contact-bg #form-messages {
  padding: 15px;
  background: #fff;
  margin-bottom: 30px;
  border-left: 3px solid;
  box-shadow: 0 2px 2px rgba(6, 6, 6, 0.04);
  transition: .30s;
}
.rs-contact .contact-bg #form-messages.success {
  border-color: #4caf50;
  color: #4caf50;
}
.rs-contact .contact-bg #form-messages.error {
  border-color: #ff3c3c;
  color: #ff3c3c;
}
.rs-contact .contact-bg #form-messages:empty {
  display: none;
}
.rs-contact .contact-bg .contact-form .form-field {
  margin-bottom: 30px;
  position: relative;
}
.rs-contact .contact-bg .contact-form .form-field i {
  color: #e88e2e;
  position: absolute;
  top: 50%;
  left: 16px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.rs-contact .contact-bg .contact-form .form-field input {
  width: 100%;
  height: 50px;
  padding: 5px 40px;
  border: 2px solid rgba(0, 0, 0, 0.9);
  transition: .20s;
}
.rs-contact .contact-bg .contact-form .form-field input:focus {
  border: 2px solid #f05c62;
  outline: none;
}
.rs-contact .contact-bg .contact-form .form-field textarea {
  width: 100%;
  padding: 10px 20px;
  border: 2px solid rgba(0, 0, 0, 0.9);
  transition: .20s;
}
.rs-contact .contact-bg .contact-form .form-field textarea:focus {
  border: 2px solid #f05c62;
  outline: none;
}
.rs-contact .contact-bg .contact-form .form-button button {
  position: relative;
  display: inline-block;
  padding: 12px 20px;
  background: #f05c62;
  color: #fff;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
  border: none;
}
.rs-contact .contact-bg .contact-form .form-button button:hover {
  background: #303745;
  color: #ffffff;
}
.contact-page #googleMap {
  height: 485px;
}
.contact-page .location-details {
  margin-top: 20px;
}
.contact-page .location-details h3 {
  font-size: 20px;
  text-transform: uppercase;
  margin: 0 0 10px;
}
.contact-page .contact-form-area {
  padding-top: 20px;
}
.contact-page .contact-form-area .form-group {
  margin-bottom: 20px;
}
.contact-page .contact-form-area h4 {
  font-size: 18px;
  text-transform: uppercase;
  padding-left: 15px;
  font-weight: 600;
  margin-bottom: 19px;
}
.contact-page .contact-form-area label {
  color: #505050;
  font-weight: normal;
  letter-spacing: 1px;
}
.contact-page .contact-form-area input,
.contact-page .contact-form-area textarea {
  background: transparent;
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 0;
  height: 45px;
  width: 100%;
}
.contact-page .contact-form-area textarea {
  height: 150px;
  width: 100%;
  max-width: 100%;
}
.contact-page .contact-form-area .btn-send {
  background: #e88e2e;
  font-weight: 700;
  color: #101010;
  font-size: 15px;
  line-height: 24px;
  border: none;
  border-radius: 0px;
  padding: 14px 23px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 14px;
  transition: all 0.3s ease 0s;
}
.contact-page .contact-form-area .btn-send:hover {
  background: #252525;
  color: #ffffff;
}
.contact-page ul#contact-list {
  background: #f0f0f0;
  padding: 25px;
}
.contact-page ul#contact-list li {
  font-size: 15px;
  font-weight: 400;
  color: #434343;
  padding-bottom: 20px;
}
.contact-page ul#contact-list li:last-child {
  padding-bottom: 0;
}
.contact-page ul#contact-list li i {
  float: left;
  font-size: 24px;
  font-weight: 700;
  color: #e88e2e;
  margin-top: 5px;
  width: 40px;
}
.contact-page ul#contact-list li .info-text {
  overflow: hidden;
}
.contact-page.contact-page3 .location-details,
.contact-page.contact-page2 .location-details,
.contact-page.contact-page3 .contact-form-area,
.contact-page.contact-page2 .contact-form-area {
  margin-top: 0;
  padding-top: 0;
}
.rs-contact .contact-top-section {
  position: relative;
}
.rs-contact .contact-top-section #googleMap {
  width: 100%;
  height: 660px;
}
.rs-contact .contact-top-section .contact-box-area {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 111;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner {
  background: #e88e2e;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .contact-form {
  background: #ffffff;
  padding: 100px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .contact-form .form-control {
  width: 100%;
  box-shadow: none;
  color: #2b323f;
  height: auto;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 0;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .contact-form textarea.form-control {
  height: 150px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .contact-form .btn {
  background: #e88e2e;
  color: #101010;
  border-radius: 0;
  padding: 10px 20px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  font-weight: 700;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .contact-form .btn:hover {
  background: #101010;
  color: #ffffff;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box {
  padding: 100px;
  padding-left: calc(70px);
  color: #101010;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box h2 {
  color: #101010;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li {
  margin-bottom: 15px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-icon {
  width: 30px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-icon i {
  font-size: 30px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-icon .fa-envelope-o {
  font-size: 25px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-details {
  padding-left: 20px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-details p {
  margin: 0;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-details,
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-icon {
  display: table-cell;
  vertical-align: top;
}
.rs-contact .footer-bottom {
  background-color: #252525;
  padding-bottom: 50px;
  padding-top: 30em;
}

@media (max-width: 576px){
  .heightWidth{
    height: 350px;
    width: 400px
  }
  .pad{
    padding-left: 0rem !important;
  }
}

.footer {
    width:100%;
    height:55px;   /* Height of the footer */
    background: rgb(210,174,109);
    border: 2px solid rgb(210,174,109);

}

.container-fluid {
  padding: 0 !important;
}
/* .bg-one{
 background: url("../assets/images/bglogo.jpg") no-repeat center center ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size:cover;
  height: 100vh;
} */
.intro-data {
  color: #18212a;
  border: 2px double #18212a;
}
.quote {
  color: black;
  /* position: absolute;
        margin-top: 12rem;
        width: 26rem;
        margin-left: 3rem; */
  text-align: justify;
}
.mt-5 {
  margin-top: 10rem !important;
}

.heading {
  font-size: 3.5rem;
}
.img-fluid {
  vertical-align: top !important;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.pr-md-5 {
  padding-right: 3rem !important;
}
.contact-btn {
  text-decoration: none;
  padding: 0.8rem;
  background: rgb(64, 168, 90);
  border: 2px solid rgb(64, 168, 90);
  color: white;
  border-radius: 5px;
}
.contact-btn:hover {
  transition: 0.3s;
  color: white;
  background: rgb(210, 174, 109);
  border: 2px solid rgb(210, 174, 109);
}
.mt-5 {
  margin-top: 7rem !important;
}
@media (max-width: 576px) {
  /* .container {
    max-width: 540px;
  } */
  .wrapping{
    flex-wrap: wrap;
  }
  .heading{
    font-size: xx-large;
  }
  .pb-4{
    padding-bottom: 0rem !important;
  }
}
@media (max-width: 768px) {
    .wrapping{
        flex-wrap: wrap;
      }
    .heading{
        font-size: xx-large;
      }
      .pb-4{
        padding-bottom: 0rem !important;
      }
}
@media (max-width: 992px) {
  /* .container {
    max-width: 960px;
  } */
  /* .wrapping{
    flex-wrap: wrap;
  } */
}
@media (max-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.fromTop{
    top: 120px;
}
.fromRight{
    left: 360px;
}
.pointer{
    cursor: pointer;
}
.pricing-container {
  margin: 0;
  padding: 0;
}
.font-12 {
  font-size: 12px;
}
.price-bar {
  background-color: #d2ae6d;
  padding: 20px 20px;
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  align-items: baseline;
}
.price-bar span {
  display: inline-flex;
  font-size: 0.5em;
  align-self: flex-end;
  text-decoration: line-through;
}
.price-bar sub {
  font-size: 0.4em;
  text-align: left;
}
.pricing-container ul {
  /* margin: 0px;
    padding: 0px; */
  list-style: none;
}
.pricing-container p {
  font-family: Poppins;
  font-size: 14px;
  color: #8a8c99;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 3.64px;
}
.pricing-container ul li {
  line-height: 1cm;
}
.pricing-container li img {
  height: 18px;
  margin-right: 5px;
}
.spec-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spec-header .toggle-div {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #d2ae6d;
}
.spec-header.collapsed .toggle-div.show,
.spec-header .toggle-div.hide {
  display: block;
}
.spec-header.collapsed .toggle-div.hide,
.spec-header .toggle-div.show {
  display: none;
}
.spec-toggle {
  margin-bottom: 20px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #d2ae6d;
  padding: 10px 20px;
}
.spec-toggle ul {
  list-style-type: disc;
}
.price-desc {
  margin-top: 20px;
  font-size: 14px;
  opacity: 0.8;
  line-height: 2;
}
.calc-div {
  margin: 0px;
  padding: 50px;
  box-shadow: #ffffff00 0px 0px 0px 0px;
  border-width: 0px;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 10px;
  background-color: #eff4f4;
}
.calc-wrapper {
  display: grid;
}
.calc-wrapper label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  margin: 20px 0 0 0;
  padding: 0px;
  font-size: 14px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  text-shadow: #ffffff00 0px 0px 0px;
  letter-spacing: 0px;
}
.calc-wrapper input,
.calc-wrapper textarea,
.calc-wrapper select {
  color: #000000;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  text-shadow: #ffffff00 0px 0px 0px;
  border-width: 1px;
  border-style: solid;
  border-color: #d0d0d0;
  border-radius: 0px;
  letter-spacing: 0px;
  background-color: #ffffff;
}
.calc-wrapper input:focus,
.calc-wrapper select:focus,
.calc-wrapper textarea {
  border-color: #d2ae6d;
  outline: #d2ae6d;
}
.calc-wrapper span {
  color: #494949;
  margin: 3px 0px;
  padding: 0px;
  font-size: 12px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  text-shadow: #ffffff00 0px 0px 0px;
  letter-spacing: 0px;
}
.calc-div table td {
  color: rgb(0, 0, 0);
  font-style: normal;
  font-size: 14px;
  text-align: left;
  font-weight: 300;
  text-shadow: #ffffff00 0px 0px 0px;
  letter-spacing: 0px;
  padding: 5px;
}
.calc-div table tr td:nth-child(2) {
  text-align: right;
}
.calc-div table tr:last-child td {
  padding-bottom: 30px;
}
.calc-div table tfoot {
  border-top: 1px solid #ddd;
}
.calc-div tfoot td {
  padding: 20px 0px;
  font-weight: bold;
}
.calc-div button.submit-btn {
  padding: 10px 15px;
  background-color: #d2ae6d;
  color: #fff;
  border: none;
  font-weight: bold;
}
.close-price_modal {
  cursor: pointer;
}
.price-modal .modal-content {
  padding: 20px;
}
.error-list li {
  list-style-type: decimal;
  color: #f94141;
}
.customer-review {
  margin: 40px 0;
}
.customer-review .carousel-item p {
  font-style: italic;
}
.customer-review .carousel-item img {
  height: 30px;
}
.customer-review .user-info {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.customer-rev-div {
  width: 600px;
  margin: auto
}
.customer-review .user-info h5 {
  margin-left: 20px;
}
.sr-only-prev i,
.sr-only-next i{
  font-size: 40px;
  color: #d2ae6d;
  font-weight: bold;
  opacity: 1;
}
@media (max-width: 576px) {
  /* .container {
    max-width: 540px;
  } */
  .headerDisplay{
    display: block !important;
  }
}
@media (max-width: 768px) {
  .headerDisplay{
    display: block !important;
  }
}
/* @media (max-width: 992px) {
  .headerDisplay{
    display: block !important;
  }
} */

