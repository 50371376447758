@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');
     /* Contact Page */

.rs-contact .contact-bg {
  overflow: hidden;
  position: relative;
}
.rs-contact .contact-bg .contact-address {
  margin-bottom: 45px;
}
.rs-contact .contact-bg .contact-address .address-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.rs-contact .contact-bg .contact-address .address-item + .address-item {
  margin-top: 25px;
}
.rs-contact .contact-bg .contact-address .address-item .address-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #333945;
  text-align: center;
  font-size: 24px;
  margin-right: 20px;
  transition: .20s;
  color: #e88e2e;
}
.rs-contact .contact-bg .contact-address .address-item:hover .address-icon {
  background: #333945;
  color: #fff;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom {
  margin-top: 55px;
  margin-bottom: 55px;
  text-align: center;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li {
  display: inline;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li a {
  text-align: center;
  text-decoration: none;
  color: #fff;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li a i {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  line-height: 40px;
  transition: .20s;
  box-shadow: inset 2px 2px 2px;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li a i:hover {
  background: #fff;
  color: #000;
}
.rs-contact .contact-bg #form-messages {
  padding: 15px;
  background: #fff;
  margin-bottom: 30px;
  border-left: 3px solid;
  box-shadow: 0 2px 2px rgba(6, 6, 6, 0.04);
  transition: .30s;
}
.rs-contact .contact-bg #form-messages.success {
  border-color: #4caf50;
  color: #4caf50;
}
.rs-contact .contact-bg #form-messages.error {
  border-color: #ff3c3c;
  color: #ff3c3c;
}
.rs-contact .contact-bg #form-messages:empty {
  display: none;
}
.rs-contact .contact-bg .contact-form .form-field {
  margin-bottom: 30px;
  position: relative;
}
.rs-contact .contact-bg .contact-form .form-field i {
  color: #e88e2e;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}
.rs-contact .contact-bg .contact-form .form-field input {
  width: 100%;
  height: 50px;
  padding: 5px 40px;
  border: 2px solid rgba(0, 0, 0, 0.9);
  transition: .20s;
}
.rs-contact .contact-bg .contact-form .form-field input:focus {
  border: 2px solid #f05c62;
  outline: none;
}
.rs-contact .contact-bg .contact-form .form-field textarea {
  width: 100%;
  padding: 10px 20px;
  border: 2px solid rgba(0, 0, 0, 0.9);
  transition: .20s;
}
.rs-contact .contact-bg .contact-form .form-field textarea:focus {
  border: 2px solid #f05c62;
  outline: none;
}
.rs-contact .contact-bg .contact-form .form-button button {
  position: relative;
  display: inline-block;
  padding: 12px 20px;
  background: #f05c62;
  color: #fff;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
  border: none;
}
.rs-contact .contact-bg .contact-form .form-button button:hover {
  background: #303745;
  color: #ffffff;
}
.contact-page #googleMap {
  height: 485px;
}
.contact-page .location-details {
  margin-top: 20px;
}
.contact-page .location-details h3 {
  font-size: 20px;
  text-transform: uppercase;
  margin: 0 0 10px;
}
.contact-page .contact-form-area {
  padding-top: 20px;
}
.contact-page .contact-form-area .form-group {
  margin-bottom: 20px;
}
.contact-page .contact-form-area h4 {
  font-size: 18px;
  text-transform: uppercase;
  padding-left: 15px;
  font-weight: 600;
  margin-bottom: 19px;
}
.contact-page .contact-form-area label {
  color: #505050;
  font-weight: normal;
  letter-spacing: 1px;
}
.contact-page .contact-form-area input,
.contact-page .contact-form-area textarea {
  background: transparent;
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 0;
  height: 45px;
  width: 100%;
}
.contact-page .contact-form-area textarea {
  height: 150px;
  width: 100%;
  max-width: 100%;
}
.contact-page .contact-form-area .btn-send {
  background: #e88e2e;
  font-weight: 700;
  color: #101010;
  font-size: 15px;
  line-height: 24px;
  border: none;
  border-radius: 0px;
  padding: 14px 23px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 14px;
  transition: all 0.3s ease 0s;
}
.contact-page .contact-form-area .btn-send:hover {
  background: #252525;
  color: #ffffff;
}
.contact-page ul#contact-list {
  background: #f0f0f0;
  padding: 25px;
}
.contact-page ul#contact-list li {
  font-size: 15px;
  font-weight: 400;
  color: #434343;
  padding-bottom: 20px;
}
.contact-page ul#contact-list li:last-child {
  padding-bottom: 0;
}
.contact-page ul#contact-list li i {
  float: left;
  font-size: 24px;
  font-weight: 700;
  color: #e88e2e;
  margin-top: 5px;
  width: 40px;
}
.contact-page ul#contact-list li .info-text {
  overflow: hidden;
}
.contact-page.contact-page3 .location-details,
.contact-page.contact-page2 .location-details,
.contact-page.contact-page3 .contact-form-area,
.contact-page.contact-page2 .contact-form-area {
  margin-top: 0;
  padding-top: 0;
}
.rs-contact .contact-top-section {
  position: relative;
}
.rs-contact .contact-top-section #googleMap {
  width: 100%;
  height: 660px;
}
.rs-contact .contact-top-section .contact-box-area {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 111;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner {
  background: #e88e2e;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .contact-form {
  background: #ffffff;
  padding: 100px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .contact-form .form-control {
  width: 100%;
  box-shadow: none;
  color: #2b323f;
  height: auto;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 0;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .contact-form textarea.form-control {
  height: 150px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .contact-form .btn {
  background: #e88e2e;
  color: #101010;
  border-radius: 0;
  padding: 10px 20px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  font-weight: 700;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .contact-form .btn:hover {
  background: #101010;
  color: #ffffff;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box {
  padding: 100px;
  padding-left: calc(70px);
  color: #101010;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box h2 {
  color: #101010;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li {
  margin-bottom: 15px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-icon {
  width: 30px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-icon i {
  font-size: 30px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-icon .fa-envelope-o {
  font-size: 25px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-details {
  padding-left: 20px;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-details p {
  margin: 0;
}
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-details,
.rs-contact .contact-top-section .contact-box-area .contact-box-inner .address-box .address-details li .info-icon {
  display: table-cell;
  vertical-align: top;
}
.rs-contact .footer-bottom {
  background-color: #252525;
  padding-bottom: 50px;
  padding-top: 30em;
}

@media (max-width: 576px){
  .heightWidth{
    height: 350px;
    width: 400px
  }
  .pad{
    padding-left: 0rem !important;
  }
}
