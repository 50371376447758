/* .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100px;
    aspect-ratio: 1;
    background-color: white;
    margin: 10px;
  }
  .container {
    padding: 2px 16px;
  } */

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .card{
    cursor: pointer;
  }
