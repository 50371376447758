.pricing-container {
  margin: 0;
  padding: 0;
}
.font-12 {
  font-size: 12px;
}
.price-bar {
  background-color: #d2ae6d;
  padding: 20px 20px;
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  align-items: baseline;
}
.price-bar span {
  display: inline-flex;
  font-size: 0.5em;
  align-self: flex-end;
  text-decoration: line-through;
}
.price-bar sub {
  font-size: 0.4em;
  text-align: left;
}
.pricing-container ul {
  /* margin: 0px;
    padding: 0px; */
  list-style: none;
}
.pricing-container p {
  font-family: Poppins;
  font-size: 14px;
  color: #8a8c99;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 3.64px;
}
.pricing-container ul li {
  line-height: 1cm;
}
.pricing-container li img {
  height: 18px;
  margin-right: 5px;
}
.spec-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spec-header .toggle-div {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #d2ae6d;
}
.spec-header.collapsed .toggle-div.show,
.spec-header .toggle-div.hide {
  display: block;
}
.spec-header.collapsed .toggle-div.hide,
.spec-header .toggle-div.show {
  display: none;
}
.spec-toggle {
  margin-bottom: 20px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #d2ae6d;
  padding: 10px 20px;
}
.spec-toggle ul {
  list-style-type: disc;
}
.price-desc {
  margin-top: 20px;
  font-size: 14px;
  opacity: 0.8;
  line-height: 2;
}
.calc-div {
  margin: 0px;
  padding: 50px;
  box-shadow: #ffffff00 0px 0px 0px 0px;
  border-width: 0px;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 10px;
  background-color: #eff4f4;
}
.calc-wrapper {
  display: grid;
}
.calc-wrapper label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  margin: 20px 0 0 0;
  padding: 0px;
  font-size: 14px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  text-shadow: #ffffff00 0px 0px 0px;
  letter-spacing: 0px;
}
.calc-wrapper input,
.calc-wrapper textarea,
.calc-wrapper select {
  color: #000000;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  text-shadow: #ffffff00 0px 0px 0px;
  border-width: 1px;
  border-style: solid;
  border-color: #d0d0d0;
  border-radius: 0px;
  letter-spacing: 0px;
  background-color: #ffffff;
}
.calc-wrapper input:focus,
.calc-wrapper select:focus,
.calc-wrapper textarea {
  border-color: #d2ae6d;
  outline: #d2ae6d;
}
.calc-wrapper span {
  color: #494949;
  margin: 3px 0px;
  padding: 0px;
  font-size: 12px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  text-shadow: #ffffff00 0px 0px 0px;
  letter-spacing: 0px;
}
.calc-div table td {
  color: rgb(0, 0, 0);
  font-style: normal;
  font-size: 14px;
  text-align: left;
  font-weight: 300;
  text-shadow: #ffffff00 0px 0px 0px;
  letter-spacing: 0px;
  padding: 5px;
}
.calc-div table tr td:nth-child(2) {
  text-align: right;
}
.calc-div table tr:last-child td {
  padding-bottom: 30px;
}
.calc-div table tfoot {
  border-top: 1px solid #ddd;
}
.calc-div tfoot td {
  padding: 20px 0px;
  font-weight: bold;
}
.calc-div button.submit-btn {
  padding: 10px 15px;
  background-color: #d2ae6d;
  color: #fff;
  border: none;
  font-weight: bold;
}
.close-price_modal {
  cursor: pointer;
}
.price-modal .modal-content {
  padding: 20px;
}
.error-list li {
  list-style-type: decimal;
  color: #f94141;
}
.customer-review {
  margin: 40px 0;
}
.customer-review .carousel-item p {
  font-style: italic;
}
.customer-review .carousel-item img {
  height: 30px;
}
.customer-review .user-info {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.customer-rev-div {
  width: 600px;
  margin: auto
}
.customer-review .user-info h5 {
  margin-left: 20px;
}
.sr-only-prev i,
.sr-only-next i{
  font-size: 40px;
  color: #d2ae6d;
  font-weight: bold;
  opacity: 1;
}
@media (max-width: 576px) {
  /* .container {
    max-width: 540px;
  } */
  .headerDisplay{
    display: block !important;
  }
}
@media (max-width: 768px) {
  .headerDisplay{
    display: block !important;
  }
}
/* @media (max-width: 992px) {
  .headerDisplay{
    display: block !important;
  }
} */
