*,
*::before,
*::after {
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  display: none;
}
body{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    font-family: poppins, sans-serif;
    display:flex;
    flex-direction:column;
}
