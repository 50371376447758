.service-section {
    margin-top: 70px;
    position: relative;
    height: 450px;
}
.service-section .service-bg {
    position: absolute;
    right: 0;
    width: 75%;
    top: 0;
    height: 100%;
    z-index: 1;
}
.service-section.service-odd .service-bg {
    background-color: #efcb88;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.service-section.service-even .service-bg {
    left: 0;
    background-color: #d2af6d27;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.service-item {
    display: flex;
    align-items: center;
    padding: 46px 0;
    z-index: 2;
    position: relative;
    margin-left: 7%;
}
.service-section.service-even .service-item {
    margin-right: 7%;
    flex-direction: row-reverse;
}
.service-section picture{
    min-width: 50%;
    max-width: 50%;
    max-height: 360px;
    border-radius: 10px;
    overflow: hidden;
}
.service-section picture img {
    width: 100%;
    height: 100%;    
    object-fit: cover;
    object-position: center; 
}
.service-content {
    margin-left: 2%
}
.displayProgress{
    display: none;
}
/* Media query !!! -> Always write the high in top as it will override  */
  @media (max-width: 1200px) {
    .display{
        display: block;
    }
    .displayProgress{
        display: none;
    }
  }
@media (max-width: 992px) {
    .display{
        display: block;
    }
    .displayProgress{
        display: none;
    }
  }
@media (max-width: 768px) {
    .display{
        display: none;
    }
    .displayProgress{
        display: block;
    }
  }
@media (max-width: 576px) {
    .display{
        display: none;
    }
    .displayProgress{
        display: block;
    }
  }

